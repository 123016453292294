import { post } from "@/utils/axios";

function $_buildCaseFiltersObj(
  caseFilters // { testStatusList, lbNames, nodeNames, alertDispGuids, testDateStart, testDateEnd, personInfoList }
) {
  let testPerson = {};
  let filtersObj = null;
  if (caseFilters) {
    for (let peronInfo of caseFilters.personInfoList) {
      testPerson[peronInfo.field] = peronInfo.value;
    }
    filtersObj = {
      testStatusList: caseFilters.testStatusList || [],
      lbNames: caseFilters.lbNames || [],
      nodeNames: caseFilters.nodeNames || [],
      alertDispGuids: caseFilters.alertDispGuids || [],
      testDateStart: caseFilters.testDateStart,
      testDateEnd: caseFilters.testDateEnd,
      testPerson
    };
  }
  return filtersObj;
}

function $_buildCasePagingObj(
  casePaging // { offset, limit }
) {
  let pagingObj = null;
  if (casePaging) {
    pagingObj = {
      offset: casePaging.offset,
      limit: casePaging.limit
    };
  }
  return pagingObj;
}

export function fetchCaseList(
  regionGuid,
  nodeGuids,
  caseFilters, // { testStatusList, lbNames, nodeNames, alertDispGuids, testDateStart, testDateEnd, personInfoList }
  casePaging, // { offset, limit }
  orderList // [ { fieldName, orderDirection } ]
) {
  return post("/case/CaseList", {
    customerGuid: regionGuid,
    nodeGuidList: nodeGuids,
    filters: $_buildCaseFiltersObj(caseFilters),
    paging: $_buildCasePagingObj(casePaging),
    orderBy: orderList
  });
}

export async function fetchCaseGuidList(
  regionGuid,
  nodeGuids,
  caseFilters // { testStatusList, lbNames, nodeNames, alertDispGuids, testDateStart, testDateEnd, personInfoList }
) {
  let response = await post("/case/SelectCaseGuidList", {
    customerGuid: regionGuid,
    nodeGuidList: nodeGuids,
    filters: $_buildCaseFiltersObj(caseFilters)
  });
  return response.guidList;
}

export function fetchLbCaseGuidListForBulkReport(caseGuidList) {
  return post("/case/CaseListGroupedByLbForBulkReport", {
    guidList: caseGuidList || []
  });
}

export function fetchNodeGuidsForReportSign(caseGuidList) {
  return post("/case/NodeGuidListForReportSign", {
    guidList: caseGuidList
  });
}

export function fetchFilterableLbNames(
  regionGuid,
  nodeGuidList,
  testStatusList
) {
  return post("/case/CaseFilterLbNames", {
    customerGuid: regionGuid,
    nodeGuidList,
    testStatusList
  });
}

export async function deleteCases(caseGuidList) {
  let response = await post("/case/DeleteCaseList", caseGuidList);
  return response.guidList;
}

export async function saveCaseMark(caseGuidList, caseMark) {
  let response = await post("/case/SaveCaseMark", {
    caseGuidList,
    caseMark
  });
  return response.guidList;
}

export async function extractExcelFromCases(
  regionGuid,
  nodeGuids,
  caseGuidList,
  isBriefScore
) {
  let response = await post("/reportanalyse/ExportScoreAndAnswerToExcel", {
    customerGuid: regionGuid,
    nodeGuidList: nodeGuids,
    caseGuidList,
    isBriefScore
  });
  return response.value;
}
